import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useAlert } from "react-alert";
import { CustomButton } from "../ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";

import {
  getConsumerGoogleAccount,
  deleteConsumerGoogleAccount,
} from "lib/api/consumer";

import { ProfileFormTitle } from "./ProfileFormTitle";
import { createNewAgent } from "../../lib/api/admin";
import {
  isEditButtonDisabled,
  isEditingForm,
  validateAdmin,
  validateConsumer,
  validateEmployer,
  validateNewAgent,
} from "./helpers";
import {
  editEmployer,
  editProfile,
  handleCancelEditingConsumer,
  saveAdminData,
  saveAgentData,
  saveConsumerData,
  saveEmployerData,
  setFormErrors,
  setOldUserEmail,
  fetchConsumerProfile,
} from "../../redux/actions/profile";
import { ConsumerProfileForm } from "./ConsumerProfileForm";
import { EmployerProfileForm } from "./EmployerProfileForm";
import { AdminProfileForm } from "./AdminProfileForm";
import {
  unformattedPhoneNumber,
  unformattedZipCode,
} from "../../lib/helpers/formatters";

import { getThirdPartyContact } from "lib/api/common";
import { ThirdPartyContainer } from "./ThirdPartyContainer";
import { AddThirdPartyModal } from "./ThirdPartyContainer/AddThirdPartyModal";
import { TwoFactorAuth } from "ConsumerPage/ProfileInfoPage/ProfileBody/TwoFactorAuth";

import "./style.scss";
import { wtError } from "lib/helpers/dataLayers";

export const ProfileBody = ({ profileType, profileData }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const isEditingProfile = useSelector(
    (state) => state.profile.isEditingProfile
  );
  const isEditingEmployer = useSelector(
    (state) => state.profile.isEditingEmployer
  );
  const formErrors = useSelector((state) => state.profile.formErrors);
  const { id } = useParams();
  const history = useHistory();
  const [isChangingPassword] = useState(false);

  const [isProfileSaving] = useState(false);
  const [isValidFord, setIsValidForm] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [thirdPartyContactArray, setThirdPartyContactArray] = useState([]);
  const [innerLoading, setInnerLoading] = useState(false);
  const [thirdPartyModal, setThirdPartyModal] = useState(false);
  const toggleThirdPartyModal = () => setThirdPartyModal(!thirdPartyModal);

  const consumersData = useSelector((state) => state.profile.profileData);
  const profileForm = useSelector((state) => state.profile.profileForm);
  const consumerEmployerForm = useSelector(
    (state) => state.profile.employerForm
  );

  const oldUserEmail = useSelector((state) => state.profile.oldUserEmail);

  useEffect(() => {
    dispatch(
      setOldUserEmail(consumersData.profile && consumersData.profile.email)
    );
  }, [consumersData]);

  useEffect(() => {
    if (profileType === "employer") {
      getThirdPartyContactFunc();
      checkProfileForGoogleAcc();
    }
  }, [profileType]);

  const getThirdPartyContactFunc = async () => {
    setInnerLoading(true);
    const data = await getThirdPartyContact(id);
    if (data.success === "True") {
      setInnerLoading(false);
      setThirdPartyContactArray(data.data);
    } else {
      setInnerLoading(false);
      wtError(data);
      alert.error(data.message);
    }
  };

  const openCreateThirdPartyModal = () => {
    toggleThirdPartyModal();
  };

  useEffect(() => {
    if (profileType === "new-agent") {
      dispatch(editProfile());
    }
    // eslint-disable-next-line
  }, [dispatch, profileType]);

  const handleEditProfile = () => {
    if (profileType === "own-employer" || profileType === "employer") {
      dispatch(editEmployer());
      return;
    }
    dispatch(editProfile());
  };

  const checkProfileForGoogleAcc = async () => {
    const data = await getConsumerGoogleAccount(id);
    if (data.success === "True") {
      if (data.google_authenticated === false) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      // console.log('Not Success');
    }
  };

  const untieGoogleAccount = async () => {
    const data = await deleteConsumerGoogleAccount(id);

    if (data.success === "True") {
      setIsDisabled(true);
    }
  };

  // Change all null values that were sent from back-end to empty string
  const convertedRequestForm = (data) => {
    let convertedForm = data;
    const keys = Object.keys(convertedForm);
    const values = Object.values(convertedForm);

    for (let i = 0; i < keys.length; i++) {
      if (values[i] === null) {
        convertedForm[keys[i]] = "";
      } else {
        convertedForm[keys[i]] = values[i];
      }
    }

    if (convertedForm.profile) {
      for (const elem in convertedForm.profile) {
        if (
          convertedForm.profile[elem] === null ||
          convertedForm.profile[elem] === consumersData.profile[elem] ||
          (convertedForm.profile[elem] === "" && !consumersData.profile[elem])
        ) {
          delete convertedForm.profile[elem];
        }
      }
    }

    return convertedForm;
  };

  const handleSave = async () => {
    // Do not make request if nothing has changed
    if (_.isEmpty(profileForm) && _.isEmpty(consumerEmployerForm)) return;

    if (profileForm.zip) {
      profileForm.zip = unformattedZipCode(profileForm.zip);
    }

    if (consumerEmployerForm.employer_zip) {
      consumerEmployerForm.employer_zip = unformattedZipCode(
        consumerEmployerForm.employer_zip
      );
    }

    if (profileForm.cell_phone) {
      profileForm.cell_phone = unformattedPhoneNumber(profileForm.cell_phone);
    }

    if (profileForm.home_phone) {
      profileForm.home_phone = unformattedPhoneNumber(profileForm.home_phone);
    }

    if (consumerEmployerForm.work_phone) {
      consumerEmployerForm.work_phone = unformattedPhoneNumber(
        consumerEmployerForm.work_phone
      );
    }

    if (profileType === "own-consumer" || profileType === "consumer") {
      dispatch(setFormErrors(validateConsumer(profileForm)));

      if (validateConsumer(profileForm).length > 0) {
        return;
      }

      // Check if email was changed to make request with or without email
      const formWithoutEmail = _.omit(profileForm, ["email"]);
      if (profileForm.email === oldUserEmail) {
        dispatch(
          saveConsumerData(
            {
              profile: formWithoutEmail,
            },
            id
          )
        );

        return;
      }

      dispatch(
        saveConsumerData(
          {
            email: profileForm.email,
            profile: formWithoutEmail,
          },
          id
        )
      );

      return;
    }

    if (profileType === "own-employer" || profileType === "employer") {
      dispatch(setFormErrors(validateEmployer(consumerEmployerForm)));
      if (validateEmployer(consumerEmployerForm).length > 0) {
        return;
      }

      dispatch(
        saveEmployerData(convertedRequestForm(consumerEmployerForm), id)
      );
      return;
    }

    if (profileType === "admin") {
      dispatch(setFormErrors(validateAdmin(profileForm)));

      if (validateAdmin(profileForm).length > 0) {
        return;
      }

      dispatch(
        saveAdminData(
          convertedRequestForm({ requestData: { profile: profileForm } })
        )
      );

      return;
    }

    if (profileType === "agent") {
      dispatch(
        saveAgentData({ profile: convertedRequestForm(profileForm) }, id)
      );
      return;
    }

    if (profileType === "new-agent") {
      dispatch(setFormErrors(validateNewAgent(profileForm)));

      if (validateNewAgent(profileForm).length > 0) {
        return;
      } else {
        const data = await createNewAgent({
          email: profileForm.email,
          password: profileForm.temporary_password,
          profile: _.omit(profileForm, ["email", "temporary_password"]),
        });

        if (data.success === "True") {
          history.push("/admin/roles");
        } else {
          wtError(data);
          alert.error(`Error: ${data.message}`);
        }
      }
      return;
    }
  };

  const handleCancel = () => {
    dispatch(fetchConsumerProfile(id));
    dispatch(handleCancelEditingConsumer());
    profileType === "new-agent" && history.push("/admin/roles");
  };

  const profileFormTitle = () => {
    if (
      profileData &&
      !isEditingProfile &&
      !isChangingPassword &&
      profileType !== "consumer" &&
      profileType !== "employer"
    ) {
      return "Profile Information";
    } else if (profileType === "change-password") {
      return "Change Password";
    } else if (profileType === "new-agent") {
      return "Create Agent";
    } else if (profileType === "own-consumer" || profileType === "consumer") {
      return "Personal Information";
    } else if (profileType === "own-employer" || profileType === "employer") {
      return "Employer Information";
    } else {
      return "Profile";
    }
  };
  const profileFormChildren = () => {
    if (isEditingForm(isEditingProfile, isEditingEmployer, profileType)) {
      return (
        <div className="profile-form-title__buttons">
          <CustomButton
            type="secondary"
            text="Cancel"
            onClickHandler={handleCancel}
            disabled={isProfileSaving}
          />
          {profileType === "new-agent" ? (
            <CustomButton
              text={"Add"}
              disabled={isValidFord}
              onClickHandler={handleSave}
            />
          ) : (
            <CustomButton
              text={"Save"}
              disabled={isProfileSaving}
              onClickHandler={handleSave}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="profile-form-title__buttons">
          <CustomButton
            type="secondary"
            text="Edit"
            onClickHandler={handleEditProfile}
            disabled={isEditButtonDisabled(
              profileType,
              isEditingEmployer,
              isEditingProfile
            )}
            penIconRequired={true}
          />
        </div>
      );
    }
  };

  if (profileType === "own-consumer" || profileType === "consumer") {
    return (
      <div className="profile-body">
        <ProfileFormTitle title={profileFormTitle()}>
          {profileFormChildren()}
        </ProfileFormTitle>
        {isEditingProfile ? (
          <div className="profile-form__error">
            {formErrors && formErrors[0]}
          </div>
        ) : (
          <></>
        )}
        <ConsumerProfileForm profileType={profileType} />
      </div>
    );
  }

  if (profileType === "own-employer" || profileType === "employer") {
    return (
      <>
        <div className="profile-body">
          <ProfileFormTitle title={profileFormTitle()}>
            {profileFormChildren()}
          </ProfileFormTitle>
          {isEditingEmployer ? (
            <div className="profile-form__error">
              {formErrors && formErrors[0]}
            </div>
          ) : (
            <></>
          )}
          <EmployerProfileForm />
        </div>
        <div className="third-container">
          <div className="profile-body">
            {innerLoading && <LoadingSpinner middleFixed={true} />}
            <div className="profile-form__block">
              {thirdPartyContactArray.map((item, i) => {
                return (
                  <div key={i}>
                    <ThirdPartyContainer data={item} />
                  </div>
                );
              })}
              <div
                className="add-contact-btn"
                onClick={() => openCreateThirdPartyModal()}
              >
                + Add 3rd Party Contact
              </div>
              {thirdPartyModal ? (
                <AddThirdPartyModal
                  toggle={toggleThirdPartyModal}
                  modal={thirdPartyModal}
                />
              ) : null}
            </div>
          </div>
          <div className="profile-form_untie-google-account">
            <h3 className="heading heading-body-bold profile-title">
              Google Account
            </h3>
            <CustomButton
              text="Untie Google Account"
              onClickHandler={() => untieGoogleAccount()}
              disabled={isDisabled}
            />
          </div>
          <div className="profile-form_untie-google-account">
            <TwoFactorAuth />
          </div>
        </div>
      </>
    );
  }

  if (profileType === "admin" || profileType === "agent") {
    return (
      <div className="profile-body tab-container__column tab-container__column--medium">
        <ProfileFormTitle title={profileFormTitle()}>
          {profileFormChildren()}
        </ProfileFormTitle>
        <div className="profile-form__error">{formErrors && formErrors[0]}</div>
        <AdminProfileForm profileType={profileType} />
      </div>
    );
  }

  if (profileType === "new-agent") {
    return (
      <div className="profile-body tab-container__column tab-container__column--medium">
        <ProfileFormTitle title={profileFormTitle()}>
          {profileFormChildren()}
        </ProfileFormTitle>
        <div className="profile-form__error">{formErrors && formErrors[0]}</div>
        <AdminProfileForm
          profileType={profileType}
          setIsValidForm={setIsValidForm}
        />
      </div>
    );
  }
};

ProfileBody.propTypes = {
  profileType: PropTypes.string,
  profileData: PropTypes.object,
};
