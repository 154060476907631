import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { format, parse, addHours } from "date-fns";

import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";

import { getPaymentsInfo, putPaymentsInfo } from "lib/api/common";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "./style.scss";

export const EditPaymentModal = ({
  modal,
  toggle,
  parentModal,
  parentToggle,
  loanId,
}) => {
  const alert = useAlert();
  const { id } = useParams();

  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  const [generalPaymentInfo, setGeneralPaymentInfo] = useState({});
  const [scheduleValue, setScheduleValues] = useState([]);
  const [remainingValue, setRemainingValue] = useState(0);
  const [requestArray, setRequestArray] = useState([]);
  const [localErrors, setLocalErrors] = useState({});

  const formatDate = (date) => format(date, "MM/dd/yyyy");
  const add12Hours = (date) => addHours(new Date(date), 12);

  useEffect(() => {
    if (modal) {
      const getPaymentInfoData = async (id, loanId) => {
        setInnerLoading(true);
        const info = await getPaymentsInfo(id, loanId);
        if (info.success === "True") {
          const paymentsWithParsedDates = info.data.payments.map((payment) => ({
            ...payment,
            date: formatDate(add12Hours(new Date(payment.date))),
          }));
          setGeneralPaymentInfo(info.data);
          setScheduleValues(paymentsWithParsedDates);
          setInnerLoading(false);
        } else {
          alert.error(info.message);
        }
      };
      getPaymentInfoData(id, loanId);
    } else {
      setRemainingValue(0);
      setScheduleValues([]);
      setRequestArray([]);
      setGeneralPaymentInfo({});
      setIsConfirmDisabled(false);
      setLocalErrors({});
    }
  }, [modal]);

  const backBtn = () => {
    setRequestArray([]);
    setGeneralPaymentInfo({});
    setScheduleValues([]);
    toggle();
  };

  const handleSaveChanges = async () => {
    const filteredArr = requestArray.reduce((acc, current) => {
      const x = acc.find((item) => item.payment_id === current.payment_id);
      return x ? acc : acc.concat([current]);
    }, []);

    for (let i = 0; i < filteredArr.length; i++) {
      filteredArr[i].edited_amount = filteredArr[i].amount;
      filteredArr[i].scheduled_payment_date = format(
        parse(filteredArr[i].date, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      );
      delete filteredArr[i].amount;
      delete filteredArr[i].status;
      delete filteredArr[i].date;
    }

    const requestBody = { payments: filteredArr };
    setInnerLoading(true);
    const info = await putPaymentsInfo(id, loanId, requestBody);
    if (info.success === "True") {
      setInnerLoading(false);
      window.location.reload(false);
    } else {
      setInnerLoading(false);
      alert.error(info.message);
      toggle();
    }
  };

  const changeInputValue = (e, i) => {
    const validated2Decimal = e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated2Decimal) {
      let newArr = [...scheduleValue];
      newArr[i].amount = +e.target.value;
      setScheduleValues(newArr);
      setRequestArray((oldArray) => [...oldArray, newArr[i]]);

      const sum = scheduleValue.reduce((accumulator, object) => {
        return accumulator + +object.amount;
      }, 0);
      getRemainingValue(sum);
    }
  };

  const changeDateValue = (date, i) => {
    if (!date) return;
    const adjustedDate = formatDate(add12Hours(date));
    let newArr = [...scheduleValue];
    newArr[i].date = adjustedDate;
    setScheduleValues(newArr);
    setRequestArray((oldArray) => [...oldArray, newArr[i]]);
    setLocalErrors((prevErrors) => ({ ...prevErrors, [i]: false }));
  };

  const getRemainingValue = (sum) => {
    let remaining = (generalPaymentInfo.total_amount - sum).toFixed(2);
    setRemainingValue(+remaining);
    setIsConfirmDisabled(+remaining !== 0);
  };

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  };

  const closeAndToggleParent = () => {
    setRequestArray([]);
    setGeneralPaymentInfo({});
    setScheduleValues([]);
    toggle();
    parentToggle();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        onOpened={closeParentModal}
        scrollable={true}
        className="edit-payment-modal"
        centered
      >
        <ModalBody>
          {innerLoading ? <LoadingSpinner middleFixed={true} /> : null}
          <div className="mod-header">
            <span>Edit Schedule</span>
            <div className="amount-values">
              <div>
                <span className="title">Total</span>
                <span className="value">
                  ${generalPaymentInfo.total_amount}
                </span>
              </div>
              <div>
                <span className="title remaining">Remaining</span>
                <span className="value">${remainingValue}</span>
              </div>
            </div>
            <button className="close-modal-btn" onClick={() => backBtn()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            <table className="schedule-table">
              <thead>
                <tr>
                  <th className="date">Date</th>
                  <th className="amount">Amount</th>
                  <th className="status">Status</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {scheduleValue?.map((item, i) => (
                  <tr key={i} className="schedule-row">
                    <td className="date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="MM/dd/yyyy"
                          id={`payment-date-${i}`}
                          value={
                            item.date
                              ? parse(item.date, "MM/dd/yyyy", new Date())
                              : null
                          }
                          onChange={(date) => changeDateValue(date, i)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          minDate={new Date()}
                          disabled={
                            item.status === "completed" ||
                            item.status === "failed"
                          }
                          error={
                            localErrors[i] &&
                            item.status !== "completed" &&
                            item.status !== "failed"
                          }
                          helperText={
                            localErrors[i] &&
                            item.status !== "completed" &&
                            item.status !== "failed"
                              ? "Please select a date"
                              : ""
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </td>
                    <td className="amount">
                      <input
                        type="number"
                        className="input input-medium"
                        name="amount_number"
                        id="amount_number"
                        value={item.amount || ""}
                        onChange={(e) => changeInputValue(e, i)}
                        disabled={item.status === "completed"}
                      />
                    </td>
                    <td className="status">
                      <div className="status-container">{item.status}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mod-footer">
            <CustomButton
              text="Cancel"
              onClickHandler={() => closeAndToggleParent()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text="Save Changes"
              onClickHandler={() => handleSaveChanges()}
              disabled={isConfirmDisabled}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

EditPaymentModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
};
